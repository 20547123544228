.deCSLn {
  padding: 0;
  border: 0;
  counter-reset: myCounter;
  background-color: #f1f4f8 !important;
  border-radius: 5px;
  .xKpFN {
    background-color: transparent;
    box-shadow: none;
    border: none;
    .xKpFN {
      border: 1px solid #dbe2eb;
      background-color: #ffffff !important;
    }
  }
  .MGliP {
  }
  .bTCjgU {
    align-items: center;
  }
  .PJoIF {
    align-items: center;
    padding: 5px;
    border-radius: 5px;
    border: none;
  }
  .iAWgrV {
    align-items: center;
  }
  .sc-VigVT.MGliP .bTCjgU::before {
    counter-increment: myCounter;
    content: counter(myCounter);
    background-color: #687d96;
    position: relative;
    height: 26px;
    width: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin-right: 20px;
    color: #ffffff;
    font-weight: 500;
  }

  //Normal Button
  .eUtPng {
    border: 1px solid transparent;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 20px;
    border-radius: 3px;
    background-color: #367bf6;
    text-transform: capitalize;
    &:focus {
      outline: none;
      box-shadow: 0px 1px 3px rgba(20, 28, 52, 0.2);
    }
  }

  button.sc-bdVaJa.eUtPng.sc-kAzzGY.cUJvfC {
    color: #f85c4a;
    background-color: #fee1dd;
    font-size: 0;
    position: relative;
    padding: 0;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    &::before {
      content: "";
      display: inline-block;
      vertical-align: middle;
      font-size: initial;
      width: 10px;
      height: 2px;
      background-image: url('data:image/svg+xml;utf8,<svg width="10" height="2" viewBox="0 0 10 2" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.74229e-08 2L0 4.37114e-07L10 0V2L8.74229e-08 2Z" fill="%23f85c4a"></path></svg>');
      background-repeat: no-repeat;
    }
    &:focus {
      outline: none;
      box-shadow: 0px 1px 3px rgba(20, 28, 52, 0.2);
    }
  }

  //RADIO
  .sc-jTzLTM {
    .sc-jzJRlG {
      position: relative;
      padding-left: 25px; /* Space for the "radio button" */
      margin-right: 20px;
      border: none;
      background: transparent;
      color: inherit;
      font-weight: 500;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 16px; /* Diameter of the "radio button" */
        height: 16px;
        border-radius: 50%;
        border: 1px solid;
        background-color: #ffffff;
      }
      &.hgRski {
        &::before {
          border-color: #367bf6;
          background-color: #367bf6;
        }
        &::after {
          content: "";
          position: absolute;
          left: 4px;
          top: 50%;
          transform: translateY(-50%);
          width: 8px; /* Diameter of the "radio button" */
          height: 8px;
          border-radius: 50%;

          background-color: #ffffff;
        }
      }
    }
  }
  .sc-gqjmRU.xKpFN {
    .sc-gqjmRU.xKpFN {
      background-color: #ffffff;
      padding: 10px;
      border-radius: 5px;
      margin-bottom: 15px;
    }
  }
}
