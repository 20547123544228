.drag-space {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #719ff9 !important;
  background: #ebf1fe;
  padding: 5px;
  border-radius: 5px;
  height: 20px;
  opacity: 0.5;
  transition: height 0.4s ease-in-out, opacity 0.4s ease-in-out, box-shadow 0.4s ease-in-out;
  &--enlarge {
    height: 100px;
    opacity: 1;
    box-shadow: 0px 4px 6px rgba(20, 28, 52, 0.2);
  }
}
