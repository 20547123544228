.freemium {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 1000;
    top: 0px;
    left: 0px;
    opacity: .5;
    filter: alpha(opacity=50); 
}
.freemium_blur{
    filter: blur(8px);
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.tms .sidenav {
    height: 100%;
    width: 160px;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #111;
    overflow-x: hidden;
    padding-top: 20px;
}

.tms .sidenav a {
    padding: 6px 8px 6px 16px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
}
.custom-popup.show-custom-popup::after {
    content: "";
    position: fixed;
    display: none;
    top: 60px;
    height: 60px;
    width: 50%;
    background-color: #F6F6F8;
  }
  
.tms .sidenav a:hover {
    color: #f1f1f1;
}

.tms .main {
    margin-left: 160px;
    /* Same as the width of the sidenav */
    font-size: 28px;
    /* Increased text to enable scrolling */
    padding: 0px 10px;
}

@media screen and (max-height: 450px) {
    .tms .sidenav {
        padding-top: 15px;
    }
    .tms .sidenav a {
        font-size: 18px;
    }
}

.icon {
    padding: 15px 25px;
    /* border-style: solid;
  border-width: 1px 1px 0 0; */
    border-right: 1px solid #D1D3D4;
}


/* .box-height{
  height: 280px;
} */

.other-height {
    height: 215px;
}

.font-style p {
    font-size: 11px;
    font-weight: 300;
}

.font-style span {
    color: #000;
    font-weight: 700;
}

.table-input-e {
    width: 80%;
}

.table-input-btn {
    background-color: #84c601;
    padding: 2.8px 5px;
    height: auto !important;
    margin-left: 10px;
    color: #fff;
    border-radius: 2px !important;
}

.date-picker-set input {
    height: auto !important;
    font-size: 13px !important;
    top: -3px;
}

.date-picker-set div:first-child {
    width: 100% !important;
    height: 27px !important;
}


/* ---------------------------new-css --------------------------------*/


/* .daterangepicker{
    display:none;
  } */

.container-style {
    padding: 0px;
    padding-top: 20px;
    padding-left: 120px;
    padding-right: 120px;
}

.set-div {
    background-color: #fff;
    margin-bottom: 17px;
}

.list-icon img {
    list-style: none;
    display: -webkit-inline-box;
    padding: 11px 12px 12px 12px;
    margin-bottom: 0;
    border-right: 1px solid #D1D3D4;
    cursor: pointer;
    vertical-align: top;
    background-color: #fff;
    max-height: 38px;
}

.list-icon img:hover,
.list-icon img:focus,
.list-icon img:visited {
    background-color: #a5db02;
}

.new-line img {
    border-right: 1px solid transparent;
}

.own-row {
    border: 1px solid #D1D3D4;
    display: flex;
    border-top: 0px solid transparent;
}

.box-height h4 {
    font-size: 14px;
    font-weight: bold;
    margin-top: 14px;
    margin-bottom: 15px;
}

.box-height p {
    font-size: 11px;
    margin-bottom: 6px;
}

.own-row .col-md-3 div p b {
    font-weight: bold !important;
    font-size: 13px;
}

.own-row .col-md-3 div p {
    font-size: 13px;
}

.own-row .col-md-3 div p span[style="font-weight: 600;"] {
    font-size: 14px;
    font-weight: bold !important;
}

.own-row .col-md-3 div .col-md-6.row {
    margin: 0;
    padding: 0;
}

.laptop-screen-pdz+.container {
    height: auto !important;
}

.add-label+div {
    margin-left: 13px !important;
}

.color-style {
    color: #9c9c9c;
}

.add-label {
    color: #fff;
    font-family: inherit;
    font-size: 12px;
    font-weight: bold;
}


/* .form-control {
    height: 32px;
    font-size: 13px;
    border-radius: 2px;
} */

.add-style a {
    display: block;
    font-size: 12px;
    color: #fff;
    font-weight: bold;
    padding: 13px 32px 13px;
    line-height: 2;
    border-right: 1px solid #6b6b6b;
    /* display: flex;
    height: 40px;*/
    width: 100%;
}

.add-style.same-2 a {
    padding: 8px 5px 8px 15px!important;
}

.add-style a:hover,
.tabactive {
    background: linear-gradient(0deg, #2B6DDA 0%, #4285F4 100%);
}

.add-style {
    padding: 0;
    text-align: center;
}

.Bill-label {
    text-transform: uppercase !important;
    color: #000;
    /* text-align: left;
    float: left; */
    font-size: 14px;
    font-weight: bold;
    /* font-family: proxima-nova, sans-serif; */
    /* padding-top: 20px; */
    /* padding-bottom: 10px; */
    font-family: inherit;
}

.new-search {
    top: 21px;
    /* left: 100px; */
    right: 8px;
    font-size: 10px;
    color: #989898;
    z-index: 0;
    height: 32px;
}

legend.Bill-label.pay-label {
    position: relative;
    top: 19px;
    margin-bottom: 25px;
    background: none;
    border-top: 1px solid #d1d1d1;
    padding-top: 14px;
}

.billing-table {
    background-color: #fff;
    margin-bottom: 0px;
    border-collapse: collapse;
    border-spacing: 0;
    /* width: 1500px; */
}

.pdz-right-5 {
    padding-right: 5px;
}

.pdz-left-5 {
    padding-left: 5px;
}

.new-over {
    overflow: auto;
    height: 275px;
}

.col-98 {
    width: 98%;
    float: left;
}

.coln-2 {
    width: 2%;
    display: inline-block;
    text-align: center;
}

.col-md-12.pdz0 .col-98 {
    width: calc(100% - 43px);
}

.col-md-12.pdz0 .coln-2 {
    width: 43px;
    margin-left: -2px;
}

.coln-2 .second-table tr th,
.coln-2 .second-table tr td {
    height: 41px;
}

.col-97 {
    width: 97%;
    float: left;
}

.coln-3 {
    width: 3%;
    display: inline-block;
    text-align: center;
}

.inline-blockn {
    display: inline-block;
}

.billing-table th {
    background-color: #fff;
    padding: 11px !important;
    padding-left: 15px !important;
    font-size: 13px;
    border-right: 1px solid #dddddd;
}

.billing-table>tbody>tr>td {
    font-size: 11px;
    padding-left: 15px;
    vertical-align: inherit;
    padding: 5px 15px;
}

.table-striped>tbody>tr:nth-of-type(odd) {
    background-color: #f8f9fa;
}

.pdz0 {
    padding: 0px;
}

.pdz3 {
    padding: 3px;
}

.second-table tr th {
    /* background-color: #fff;
    border: 1px solid #D1D3D4;
    padding: 7px;
    cursor: pointer; */
    background-color: #f8f8f8;
    border: 1px solid #D1D3D4;
    padding: 0px;
    cursor: pointer;
    width: 28px;
    height: 28px;
}

.second-table tr td {
    /* background-color: #fff;
    border: 1px solid #D1D3D4;
    padding: 7px;
    cursor: pointer; */
    background-color: #f8f8f8;
    border: 1px solid #D1D3D4;
    padding: 0px;
    cursor: pointer;
    width: 28px;
    height: 28px;
}

.table-input>td>input {
    width: 100%;
    height: 25px;
    /* border: 1px solid #c5c5c5 !important; */
    border: 1px solid #c5c5c5;
    padding-left: 5px;
    border-radius: 2px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 2px 0px !important;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 2px 0px !important;
}

.input-group>.form-control {
    z-index: 0;
}

.table-style {
    background-color: #fff;
    margin-bottom: 0px;
}

.n-billing-table tr td.td-sec:hover {
    background-color: #FFF;
    box-shadow: inset 2px 2px 6px 0 rgba(0, 0, 0, 0.2);
}

.n-billing-table tr td.td-sec:active {
    box-shadow: inset 2px 2px 6px 0 rgba(0, 0, 0, 0.2);
    transform: scale(1.05);
}

.th-first {
    font-size: 13px;
    font-weight: bold;
    color: #000;
    text-align: left;
    width: 180px;
    background-color: #fff;
}

.td-sec {
    font-size: 13px;
    font-weight: 100;
    color: #000;
    text-align: left;
}

.table-style>tbody>tr>td,
.table-style>tbody>tr>th,
.table-style>tfoot>tr>td,
.table-style>tfoot>tr>th,
.table-style>thead>tr>td,
.table-style>thead>tr>th {
    padding: 11px;
}

.n-billing-table>tbody>tr:nth-of-type(odd) {
    background-color: #f9f9f9;
}


/* checkbox style */

.checkbox {
    padding-left: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.checkbox label {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding-left: 10px;
    font-size: 14px;
}

.checkbox label::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 17px;
    height: 17px;
    left: 0;
    margin-left: -22px;
    border: 1px solid #cccccc;
    border-radius: 2px;
    background-color: #fff;
    -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}

.checkbox label::after {
    display: inline-block;
    position: absolute;
    width: 16px;
    height: 16px;
    left: 0;
    top: -1px;
    margin-left: -23px;
    padding-left: 4px;
    padding-top: 1px;
    font-size: 10px;
    color: #555555;
    font-weight: normal;
}

.checkbox input[type="checkbox"] {
    opacity: 0;
    z-index: 1;
}

.checkbox input[type="checkbox"]:focus+label::before {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

.checkbox input[type="checkbox"]:checked+label::after {
    font-family: 'FontAwesome';
    content: "\f00c";
}

.checkbox input[type="checkbox"]:disabled+label {
    opacity: 0.65;
}

.checkbox input[type="checkbox"]:disabled+label::before {
    background-color: #eeeeee;
    cursor: not-allowed;
}

.checkbox.checkbox-circle label::before {
    border-radius: 50%;
}

.checkbox.checkbox-inline {
    margin-top: 0;
}

.checkbox-primary input[type="checkbox"]:checked+label::before {
    /* background-color: #428bca; */
    border-color: #A5DB03;
    background: linear-gradient(0deg, #93C206 0%, #A5DB03 100%);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.11);
}

.checkbox-primary input[type="checkbox"]:checked+label::after {
    color: #fff;
}

.check-bg {
    background-color: #fff;
    padding: 1px;
    border: 1px solid #D1D3D4;
    padding-left: 15px;
    height: 40px;
    line-height: 29px;
}

.check-btn {
    border-radius: 4px;
    background: linear-gradient(0deg, #7EC206 0%, #A5DB03 100%);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.11);
    color: #fff;
    width: 100%;
    border: 1px solid #A5DB03;
    font-size: 13px;
    font-weight: bold;
    padding: 18px;
    line-height: 0 !important;
    height: 20px !important;
    font-size: 13px !important;
}

.fa-fw {
    display: none;
}

.Check-label {
    font-size: 13px !important;
    text-transform: capitalize;
    color: #000;
    line-height: 1.6;
}

.same-sapce>span {
    position: relative;
    top: 1px;
}

.btn-view {
    background-color: #a5db02;
    color: #000;
    font-size: 13px !important;
    padding: 2px 10px !important;
    line-height: 0 !important;
}

.disp-check input[type=checkbox] {
    margin-left: -22px;
    margin-top: 0px;
    cursor: pointer;
    width: 20px;
    height: 20px;
}

.cs-bill input[type=checkbox] {
    /* margin: 4px 0 0; */
    margin-top: 1px\9;
    line-height: normal;
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.load-histry-table {
    overflow: auto;
    height: 210px;
}

.load-histry-bg {
    background-color: #fff;
    margin-bottom: 0px;
    border-collapse: collapse;
    border-spacing: 0;
    width: 1100px;
}

.ul-location {
    margin: 0px;
    padding: 0px;
    list-style: none;
}

.ul-location li {
    margin-bottom: 8px;
    border: 1px solid #D1D3D4;
    background-color: #FFFFFF;
    font-size: 13px;
    font-weight: bold;
    height: 48px;
    padding: 8px 8px 0px;
    line-height: 2.7;
}

.ul-location li>span {
    padding-left: 8px;
}

.ul-location li a>img {
    width: 30px;
}

.laptop-screen-pdz {
    padding-top: 20px;
    padding-left: 21px;
    padding-right: 21px;
    padding-bottom: 20px;
}

.box-style-n {
    background-color: white;
    border: 1px solid #d1d3d4;
    padding: 15px 15px;
    -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.05);
    border-radius: 0px;
}

.errorClass {
    border: 1px solid red !important;
}

.add-style.same-2 {
    max-width: 342px;
    width: 342px;
}

@media (max-width: 1366px) {
    /* .box-height {
        height: 300px;
    } */
    .th-first {
        width: 120px;
    }
    .other-height {
        height: 215px;
    }
}

@media (max-width:1830px) and (min-width:1367px) {
    .customer-md-4 {
        width: 30%;
    }
    .customer-md-3 {
        width: 23.33%;
    }
}

@media (max-width: 1365px) and (min-width:1280px) {
    .customer-md-4 {
        width: 30%;
    }
    .customer-md-3 {
        width: 23.33%;
    }
    /* .icon {
        padding: 10px 20px;
    } */
    /* .box-height {
        height: 290px;
    } */
}

.tow-colum-part .multiselect-container>li>a>label:hover {
    background: #a7d902;
    color: white;
}

.disabled-pointer-events{
    pointer-events: none;
    opacity: 0.7;
  }