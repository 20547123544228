.w-150 {
    width: 150px !important;
}

/* ------------  Custom Row Start ---------------- */
.driver-icon{
    height: 32px;
    width: 32px;
    cursor: pointer;
}
/* ------------  Custom Row End ---------------- */


/* ---------- Bottom Action Bar start ----------- */

.batch-actions-menu-wrapper .num-of-actions_wrapper {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    color: #ffffff;
    background-color: #0085ff;
    border-radius: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    cursor: default;
}
.batch-actions-menu-wrapper .batch-actions-title-section {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    cursor: default;
    margin-right: 10px;
    margin-left: 10px;
}
.batch-actions-menu-wrapper .batch-actions-title-section .title {
    color: #323338;
    font-size: 18px;
    font-weight: 100;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 300px;
    font-weight: 400;
}
.batch-actions-title-section .pulses_dots {
    color: #333;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 20px;
    position: absolute;
    bottom: 0;
}
.batch-actions-title-section .pulses_dots .dot {
    width: 8px;
    height: 8px;
    border-radius: 20px;
    margin-right: 5px;
    background-color: #0085ff;
}
.batch-actions-title-section .pulses_dots .extra-dots {
    font-size: 12px;
    color: #808080;
}

.batch-actions-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    color: #7b8190;
    height: 100%;
    padding: 0 5px;
    width: 100%;
}
.batch-actions-menu-wrapper .batch-actions-delete-item {
    display: flex;
    padding: 15px;
    cursor: pointer;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    border-left: 1px solid;
    border-left-color: #f5f6f8;
    font-size: 20px;
}
.batch-actions-item .action-name:not(.disable) {
    color: #323338;
    color: var(--primary-text-color);
}
.batch-actions-item:hover{
    background-color: #f3f8fb;
    color: #3b93f1;
}
.batch-actions-item .action-name {
    font-size: 13px;
    position: relative;
    white-space: nowrap;
}
.batch-actions-menu-wrapper .num-of-actions_wrapper .num-of-actions {
    font-size: 16px;
}
.batch-actions-item .action-icon {
    font-size: 18px;
    position: relative;
    
}

.css-1a5snlm-menu {
    z-index: 999;
}

@media (max-width: 1220px) and (min-width: 768px){
    .batch-actions-menu-wrapper .num-of-actions {
        font-size: 24px !important;
    }
}
.disabled{
    color: #d5d5d5;
}
.batch-actions-menu-wrapper .dialog-wrapper {
    display: flex;
    position: relative;
    min-width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.select-menu-navigator-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 10px;
}
.batch-actions-menu-wrapper .dialog-wrapper .carret {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: #fff;
    top: -50px;
    left: 50%;
    transform: rotate(45deg) translateX(-50%);
}
.duplicate-dialog .select-menu-navigator-item {
    justify-content: start;
}
.select-menu-navigator-item {
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 5px 0;
}
.duplicate-dialog .select-menu-navigator-item .select-menu-navigator-icon {
    font-size: 16px;
}

.select-menu-navigator-item .select-menu-navigator-icon {
    margin-right: 10px;
    font-size: 18px;
    color: #0085ff;
}
.batch-actions-dialog.duplicate-dialog{
    position: absolute;
    bottom: 80px;
    width: 200px;
    background: white;
    box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 1px rgba(255, 255, 255, 0.5);
    left: 50%;
    transform: translateX(-50%);
    border-radius: 4px;
}
.fade-enter-done{
    visibility: hidden;
}
.fade-enter-done  .rdtPicker{
    bottom: 50px;
}
.show{
    transition: visibility 0.2s linear;
    visibility: visible;
}
/* ---------- Bottom Action Bar end ----------- */


.z-index-low > thead > tr > th {
    z-index: 0;
}

.small-date-text {
    font-size: smaller;
}

.custom-active {
    background-color: #edf1f5 !important;
    -webkit-box-shadow: 0px 6px 20px 0px #ccc !important;
    box-shadow: 0px 6px 20px 0px #ccc !important;
}

.line-height-inherit {
    line-height: inherit !important;
}

.load_number_cell:hover .openload-btn {
    display: block !important;
}

.load_number_cell .draggable {
   display: none;
}

.rdg-row:hover .load_number_cell .draggable {
    display: flex;
    cursor: grab;
}