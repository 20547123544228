/*autocomplete inputbox*/
.k-input{
 width: 100%;
 outline: none;
 border: none;
 padding: 20px 0px;
}
.k-list-scroller{
 overflow: auto;
 height: 250px;
}
.popupLi {
 cursor: pointer;
 border: 1px solid #c9c9c9;
 border-radius: 20px;
 height: 40px;
 width: 40px;
 -ms-flex-pack: center;
 justify-content: center;
 -ms-flex-align: center;
 align-items: center;
 display: flex;
}
.k-nodata{
 background-color: #eee;
 text-align: center;
}
