.pageLoader{
    position: fixed;
    top: 0;
    left: 0;
    height: "100%";
    width: "100%";
    background-color: black;
    opacity: 0.5;
    z-index: 1100;
}

.loader-cell {
    text-align: center;
  }
  
.small-loader {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 4px solid #ccc;
  border-radius: 50%;
  border-top: 4px solid #687d96;
  animation: spin 1s linear infinite;
  box-sizing: border-box;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}