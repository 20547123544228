.custom-ui {
    /* text-align: center;
  width: 500px;
  padding: 40px;
  background: #28bae6;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
  color: #fff; */
}

.custom-ui>h1 {
    margin-top: 0;
}

.custom-ui>button {
    /* width: 160px;
  padding: 10px;
  border: 1px solid #fff;
  margin: 10px;
  cursor: pointer;
  background: none;
  color: #fff;
  font-size: 14px; */
}
