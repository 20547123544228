.login-page {
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.625;
  color: #102533;
  text-align: left;
  background-color: #f3f8ff;
  padding: 0;
  min-height: 100vh;
  /* Responsive adjustments */ }
  .login-page .form-group {
    margin-bottom: 24px; }
  .login-page .form-control[type="text"],
  .login-page .form-control[type="password"],
  .login-page .form-control[type="email"],
  .login-page .form-control[type="tel"],
  .login-page textarea.form-control {
    border: 1px solid #DBE2EB;
    -webkit-border-radius: 3px;
    border-radius: 12px;
    min-height: 56px;
    width: 100%;
    padding: 10px 20px;
    font-size: 14px;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out; }
    .login-page .form-control[type="text"]:active, .login-page .form-control[type="text"]:focus,
    .login-page .form-control[type="password"]:active,
    .login-page .form-control[type="password"]:focus,
    .login-page .form-control[type="email"]:active,
    .login-page .form-control[type="email"]:focus,
    .login-page .form-control[type="tel"]:active,
    .login-page .form-control[type="tel"]:focus,
    .login-page textarea.form-control:active,
    .login-page textarea.form-control:focus {
      box-shadow: none;
      border-color: #3640F5; }
  .login-page input::-webkit-input-placeholder,
  .login-page textarea::-webkit-input-placeholder {
    font-weight: 400;
    font-size: 14px;
    color: #879AAF; }
  .login-page input:-ms-input-placeholder,
  .login-page textarea:-ms-input-placeholder {
    font-weight: 400;
    font-size: 14px;
    color: #879AAF; }
  .login-page input::placeholder,
  .login-page textarea::placeholder {
    font-weight: 400;
    font-size: 14px;
    color: #879AAF; }
  .login-page .otp-main-input input {
    padding: 0; }
  .login-page .otp-main-input input[type="tel"]::placeholder {
    font-size: 30px; }
  .login-page .bg-blue-gradient {
    background: linear-gradient(180deg, #367BF6 0%, #2F6ADD 100%); }
  .login-page .bg-light-blue-gradient {
    background: linear-gradient(0deg, #4884F7, #4884F7), linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%); }
  .login-page .login-sidebox {
    position: relative;
    border-radius: 12px; }
  .login-page .login-sidebox::before {
    content: '';
    position: absolute;
    top: -1px;
    /* Adjust to match border width */
    left: -1px;
    /* Adjust to match border width */
    right: -1px;
    /* Adjust to match border width */
    bottom: -1px;
    /* Adjust to match border width */
    z-index: -1;
    border-radius: inherit;
    /* Inherit border radius from parent */
    background: linear-gradient(180deg, #FFFFFF, rgba(255, 255, 255, 0));
    /* Apply linear gradient */ }
  .login-page .text-neutral-500 {
    color: #687D96; }
  .login-page .text-neutral-600 {
    color: #718096; }
  .login-page .mb-100 {
    margin-bottom: 100px; }
  .login-page .m-24 {
    margin: 24px; }
  .login-page .mt-32 {
    margin-top: 32px; }
  .login-page .mb-32 {
    margin-bottom: 32px; }
  .login-page .mb-24 {
    margin-bottom: 24px; }
  .login-page .mb-16 {
    margin-bottom: 16px; }
  .login-page .p-16 {
    padding: 16px; }
  .login-page .p-24 {
    padding: 24px; }
  .login-page .p-36 {
    padding: 36px; }
  .login-page .py-36 {
    padding-top: 36px;
    padding-bottom: 36px; }
  .login-page .rounded-8 {
    border-radius: 8px; }
  .login-page .rounded-12 {
    border-radius: 12px; }
  .login-page .title-64 {
    font-weight: 600;
    font-size: 4rem;
    /* 64px converted to rem */
    line-height: 4.375rem;
    /* 70px converted to rem */
    letter-spacing: -0.125rem;
    /* -2px converted to rem */ }
  .login-page .title-48 {
    font-weight: 600;
    font-size: 3rem;
    /* 48px converted to rem */
    line-height: 3.6rem;
    /* 57.6px converted to rem */
    letter-spacing: -0.03125rem;
    /* -0.5px converted to rem */ }
  .login-page .title-32 {
    font-size: 2rem;
    /* 32px converted to rem */
    font-weight: 600;
    line-height: 2.625rem;
    /* 42px converted to rem */ }
  .login-page .title-24 {
    font-size: 1.5rem;
    /* 24px converted to rem */
    font-weight: 600;
    line-height: 2.25rem;
    /* 36px converted to rem */ }
  .login-page .title-18 {
    font-weight: 500;
    font-size: 1.125rem;
    /* 18px converted to rem */
    line-height: 1.6875rem;
    /* 27px converted to rem */ }
  .login-page .title-16 {
    font-size: 1rem;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.3px; }
  @media screen and (max-width: 1024px) {
    .login-page .title-64 {
      font-size: 3.5rem;
      line-height: 4rem; }
    .login-page .title-48 {
      font-size: 2.5rem;
      line-height: 3.2rem; }
    .login-page .title-32 {
      font-size: 1.8rem;
      line-height: 2.3rem; }
    .login-page .title-24 {
      font-size: 1.3rem;
      line-height: 1.8rem; }
    .login-page .title-18 {
      font-size: 1rem;
      line-height: 1.5rem; }
    .login-page .title-16 {
      font-size: 1rem;
      line-height: 1.25rem; } }
  .login-page .form-group label {
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: 500; }
  .login-page input:focus,
  .login-page textarea:focus {
    outline: 0;
    border: 1px solid #3640F5; }
  .login-page .sign-up-complete-image {
    width: calc(50% - 8px);
    margin: 0;
    object-fit: contain; }
  .login-page .otp-input > input {
    border-radius: 12px;
    border: 1px solid #E8E7E0; }
    .login-page .otp-input > input:focus, .login-page .otp-input > input:active {
      border-color: #3640F5; }
  .login-page .login-left-sidebar {
    max-width: 600px;
    max-height: 100vh; }
    @media screen and (max-height: 992px) {
      .login-page .login-left-sidebar {
        min-height: 100vh;
        max-height: 992px; } }
  .login-page .btn-signin {
    background-color: #367BF6;
    background: linear-gradient(180deg, #367BF6 13.02%, #1F67E9 82.81%), linear-gradient(180deg, #719FF9 0%, #367CF7 100%);
    border-radius: 12px !important;
    box-shadow: none;
    color: #fff;
    font-weight: 500;
    min-height: 40px;
    width: 100%;
    padding: 10px;
    outline: none;
    transition: all 0.3s ease-in-out;
    border: 1px solid #367BF6; }
    .login-page .btn-signin:hover {
      background-color: #4884f7;
      color: #fff; }
    .login-page .btn-signin:focus, .login-page .btn-signin:active {
      outline: none; }
    .login-page .btn-signin--outline {
      background: none;
      background-color: #ffffff;
      color: #172A41;
      border: 1px solid #EDEDED; }
    .login-page .btn-signin:disabled {
      opacity: 0.75; }

@media only screen and (min-width: 992px) {
  .isloginpage #main-wrapper .page-wrapper {
    top: 0;
    height: auto;
    margin-left: 0; } }

.validation-page .page-wrapper {
  top: 0 !important;
  margin-left: 0px !important;
  height: calc(100vh - 0px) !important;
  overflow: hidden !important; }

@media only screen and (max-width: 992px) {
  .isloginpage #main-wrapper .page-wrapper {
    top: 0px;
    height: auto;
    margin-left: 0; } }
