.sidebarStyle {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  margin: 12px;
  background-color: #404040;
  color: #ffffff;
  z-index: 1 !important;
  padding: 6px;
  font-weight: bold;
}

.mapContainer {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
}

.div-invisible {
  display: none;
}

.react-mapbox-ac-suggestion {
  top: 100%;
  padding: 0;
}

.address-span {
  padding: 7px 15px;
}