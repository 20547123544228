.driver-list {
    /*height: 425px;*/
    /* overflow-y: auto;
  overflow-x: hidden;
  padding-right: 0px; */
}

.eld-log {
    font-size: 12px;
    margin-top: 16px;
}

.eld-log-right-bar {
    border-right: 1px solid;
}

.eld-log-status {
    color: #A5DB03;
}

.track-time {
    margin-top: 5px
}

.drive-status {
    padding-left: 10px !important;
    padding-right: 0px !important;
}

.shift-status {
    padding-left: 0px !important;
    margin-right: 8px !important;
}

.cycle-status {
    padding-left: 0px !important;
}

.badge2 {
    display: inline-block;
    min-width: 10px;
    padding: 3px 7px;
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    background-color: #777;
    border-radius: 10px;
}

.badge1 {
    display: inline-block;
    min-width: 10px;
    padding: 3px 7px;
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    background-color: #A5DB03;
    border-radius: 10px;
}

.clear-list {
    cursor: pointer;
}

.active-driver-item{
     background: rgba(91, 115, 232, 0.095);
}