.freemium {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1000;
  top: 0px;
  left: 0px;
  opacity: 0.5;
  filter: alpha(opacity=50);
}
.freemium_blur {
  filter: blur(8px);
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}
.count-event {
  height: 30px;
  text-align: center;
  font-size: 20px !important;
  background-color: white !important;
  color: #c31b10 !important;
  border: none !important;
}
.customTable th,
.customTable td {
  padding: 0px !important;
  height: 20px !important;
}
.overflw {
  overflow: auto !important;
}

.overflw::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(255,255,255,1); */
  border-radius: 10px;
  background-color: transparent;
}

.overflw::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}

.overflw::-webkit-scrollbar-thumb {
  border-radius: 6px;
  /* -webkit-box-shadow: inset 0 0 6px rgba(255,255,255,1); */
  background-color: rgba(255, 255, 255, 1);
}

.react-confirm-alert-overlay {
  z-index: 1051 !important;
}

.border-thin {
  border-width: 2px;
  border-color: rgba(0, 0, 0, 0.1);
}
.border-bold {
  border-width: 2px;
  border-color: rgba(0, 0, 0, 0.3);
}

.grabbable {
  cursor: move;
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.btm-border {
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.btn-opacity-low {
  opacity: 0.6;
  cursor: auto !important;
}

.small-control .form-control {
  height: 26px;
  padding: 0rem 0.75rem;
  font-size: 11px;
}

.driver-order-status-text {
  margin: 0px;
  font-size: smaller;
  font-weight: bold;
}

.kanban-card-view {
  border-width: 1px;
  border-style: dashed;
  border-radius: 8px;
  background-color: #efefef;
}

.no-border .react-datetime-picker__wrapper {
  border-width: 0px !important;
}

.main-cstm-charge.table-card tbody tr:hover td:first-child {
  overflow: inherit;
}
