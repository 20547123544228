.msg_box {
    position: fixed;
    bottom: 0;
    background: white;
    border-radius: 5px 5px 0px 0px;
    box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    z-index: 49;
    width: 328px;
    max-height: calc(100vh - 60px - 24px);
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    overflow-x: hidden;
}

.msg_head {
    background: #ffffff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1), 0 -1px rgba(0, 0, 0, 0.1) inset, 0 2px 1px -1px rgba(255, 255, 255, 0.5) inset;
    color: #050505;
    padding: 8px;
    font-size: .9375rem;
    font-weight: 500;
    cursor: pointer;
    border-radius: 8px 8px 0px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 48px;
    width: 100%;
    overflow-x: hidden;
    flex-shrink: 0;
    position: relative;
    z-index: 1;
}

.msg_head h5 {
    margin-bottom: 0;
}

.msg_wrap {
    transition: display 1.5s cubic-bezier(0.5, 0.94, 0.93, 0.51) 0s;
    display: flex;
    height: 352px;
    flex-grow: 1;
    flex-basis: 0px;
    flex-direction: column;
    position: relative;
}

.msg_body {
    background: white;
    font-size: 12px;
    padding: 15px;
    overflow: auto;
    overflow-x: hidden;
    position: relative;
    flex-basis: 0px;
    flex-grow: 1;
    min-height: 280px;
}

.msg_box__picture {
    width: 32px;
    height: 32px;
    margin-right: 8px;
}

.msg_box__picture img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.msg_input {
    height: 37px;
    border-top: 1px solid #DDDDDD;
    box-sizing: border-box;
    width: 100%;
    border: none;
    background-color: #f0f2f5;
    border-radius: 50px;
    padding-left: 12px;
}

.dragging {
    border: 2px dashed red;
    cursor: copy;
}

.msg_head .close {
    margin-left: auto;
    cursor: pointer;
    font-size: .9375rem;
    text-shadow: none;
    color: #bec2ca;
}

.msg_footer form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
}

.cancel-button {
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    height: 25px;
    width: 25px;
}

.minimize {
    float: right;
    cursor: pointer;
    padding-right: 5px;
}

.msg_row {
    position: relative;
    display: flex;
    flex-direction: row;
    margin-bottom: 2px;
}

.msg-right {
    text-align: right;
    justify-content: flex-end;
    align-items: flex-end;
}

.msg-left {
    text-align: left;
}

.msg-right .msg-img {
    margin-left: 10px;
}

.msg-left .msg-img {
    margin-right: 10px;
}

.msg-right .msg-box {
    margin-left: auto;
    background: rgba(0, 132, 255, 1);
    color: #fff;
}

.msg-left .msg-box {
    margin-right: auto;
    background-color: #f1f0f0;
    color: rgba(0, 0, 0, 1);
}

.msg-left .msg-box a {
    color: rgba(0, 0, 0, 1);
    text-decoration: underline;
}

.msg-right .msg-box a {
    color: #ffffff;
    text-decoration: underline;
}

.msg-box {
    padding: 6px 15px;
    width: fit-content;
    max-width: 185px;
    position: static;
    border-radius: 18px;
    word-break: break-word;
    font-size: .9375rem;
}

.msg-box__time {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    width: fit-content;
    z-index: 0;
    left: 50%;
    transform: translatex(-50%);
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    top: 0;
    transition: cubic-bezier(0.6, -0.28, 0.735, 0.045);
    padding: 1px 5px;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.8);
}

.msg-box:hover .msg-box__time {
    visibility: visible;
    opacity: 1;
    z-index: 2;
}

.msg-img {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    overflow: hidden;
}

.msg-img img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}
/* 
.notification-icon-header {
    height: 19px;
    min-width: 19px;
    font-size: .8125rem;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    display: flex;
    background: #f02849;
    border-radius: 50%;
    position: absolute;
    left: 26px;
    top: -6px;
    color: #ffffff;
}

.notification-icon-lists {
    height: 20px;
    width: 20px;
    font-size: 10px;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    display: flex;
    background: red;
    border-radius: 100%;
    -webkit-box-shadow: 0 6px 10px rgba(255, 0, 0, 0.2);
    box-shadow: 0 6px 10px rgba(255, 0, 0, 0.2);
    color: #fff;
} */

.msg-document {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.chevron-container{
    cursor: pointer;
    color: #3a93f1;
    font-size: 12px;
    position: absolute;
    right: 10px;
    padding: 20px;
}
.chevron-container:hover{
    background-color: #DDD;
}